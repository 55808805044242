/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import LayoutGeneral from '../components/Layouts'
import Wrapper from '../components/Wrapper'
import SEO from '../components/SEO'
import RelatedPosts from '../components/RelatedPosts'
import LayoutBigCloud from "../components/LayoutBigCloud"

const MainTitle = styled.h1`
  line-height: 1.5;
  text-align: center;
  font-size: 3rem;
  color:black;
  position:relative;
  //font-family: 'Verdana, Geneva, sans-serif';
  font-weight: bold;
`

const Text = styled.h2`
  line-height: 1.2;
  text-align: center;
  font-size: 1.5rem;
  color:black;
  position:relative;
  //font-family: 'Verdana, Geneva, sans-serif';
  font-weight: bold;
`

const SubTitle = styled.h2`
  line-height: 1.2;
  font-size: 2rem;
  text-align: left;
  color:grey;
  position:relative;
  //font-family: 'Verdana, Geneva, sans-serif';
  font-weight: bold;
`

const NotFoundPage = props => {
  const data = useStaticQuery(graphql`
    query {
      posts: allMdx(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {
          fileAbsolutePath: { regex: "//content/posts//" }
          frontmatter: { published: { ne: false }, unlisted: { ne: true } }
        }
        limit: 3
      ) {
        edges {
          node {
            fields{
              slug
            }
            excerpt
            frontmatter {
              date(formatString: "DD MMMM, YYYY")
              title
              tags
              language
              slug
            }
          }
        }
      }
    }
  `)

  const posts = data.posts.edges

  return (
    <LayoutBigCloud location={props.location} noCover={true} title="Page Not Found">

        <MainTitle>404 page not found</MainTitle>
        <Text>
          Looks like you've followed a broken link or entered a URL that doesn't
          exist on this site.
        </Text>

        <SubTitle>Recent Posts</SubTitle>
        <RelatedPosts posts={posts} />
    </LayoutBigCloud>
  )
}

export default NotFoundPage
